import cn from 'classnames'
import Avatar from 'boring-avatars'
import UIAddress from './Address'

interface Props {
    address: string
    onClick?: () => void
}

export default function AvatarAddress({
    address,
    onClick
}: Props) {
    if (!address) return null

    return (
        <div onClick={onClick} className={cn('inline-flex flex-row items-center justify-start prose prose-neutral dark:prose-invert max-w-none',{
            'cursor-pointer': onClick != null
        })}>
            <div className='mr-2 relative' style={{ top: -2 }}>
                <Avatar
                    size={22}
                    name={address}
                    variant="marble"
                    colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                />
            </div>
            <span className='font-normal relative transition-colors duration-200 text-neutral-500 group-hover:text-black dark:text-neutral-300 dark:group-hover:text-white' style={{ lineHeight: 1, letterSpacing: '1px' }}>
                <UIAddress address={address} />
            </span>
        </div>
    )
}