import cn from 'classnames'
import { useInterval } from 'common/hooks'
import { intervalToDuration } from 'date-fns'
import { memo, useState } from 'react'

interface Props {
    till: Date
}

function formatLeftPad(num?: number | null) {
    if (!num) return '00'
    return `${num < 10 ? '0' : ''}${num ?? '0'}`
}

function formatDur(dur: Duration) {
    const hours = formatLeftPad(dur.hours)
    const minutes = formatLeftPad(dur.minutes)
    const seconds = formatLeftPad(dur.seconds)

    if (dur.hours && dur.hours > 0) {
        return `${hours}:${minutes}:${seconds}`
    }

    return `${minutes}:${seconds}`
}

export default memo(function CountDown({
    till
}: Props) {
    const [dur, setDur] = useState({
        start: new Date(),
        end: till
    })

    useInterval(() => {
        const now = new Date()
        if (now < till) setDur({ start: new Date(), end: till })
    }, 1000)

    if (dur.end <= dur.start) return null

    return (
        <>
            {formatDur(intervalToDuration(dur))}
        </>
    )
})