import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { PanelPadding } from 'ui/Panel'
import Check from '../ui/check.svg'

interface Props {
    returnUrl?: string | null
    isWidget?: boolean
}

export default function CompletePayment({
    returnUrl,
    isWidget
}: Props) {
    return (
        <PanelPadding>
            <div className='flex flex-col items-center justify-center py-8'>
                <div>
                    <Check className="w-20 dark:fill-teal-400 fill-teal-500 check-animation" />
                </div>

                <div className='mt-4'>
                    <FormattedMessage defaultMessage="Payment completed!" id="XNYMIQ" />
                </div>

                {returnUrl && (
                    <div className='mt-2 opacity-40'>
                        <FormattedMessage defaultMessage="You will be redirected back to the store now. Please, wait..." id="0Jc18O" />
                    </div>
                )}

                {(!returnUrl && isWidget) && (
                    <div className='mt-2 opacity-40'>
                        <FormattedMessage defaultMessage="This browser tab will be closed in a moment. You can return to the store." id="KgjHAX" />
                    </div>
                )}
            </div>
        </PanelPadding>
    )
}