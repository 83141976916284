import { useMemo } from "react"
import { isZero, normalize, precisionOf, rescale, scaleOf } from "../common/num"
import { Hint } from "./Hint"

interface Props {
    value: string | null
    maxPrecision?: number
}

export default function Num({ value, maxPrecision }: Props) {
    const [renderValue, downscaled] = useMemo(() => {
        if (value == null || maxPrecision == null) return [value, false]
        if (isZero(value)) return ['0', false]

        const precision = precisionOf(value)
        if (precision <= maxPrecision) return [value, false]

        const scale = scaleOf(value) + maxPrecision - precision
        return [rescale(value, scale), true]
    }, [value, maxPrecision])


    if (value == null) return <span className="opacity-50">...</span>

    return (
        <Hint isHidden={!downscaled} interactive content={normalize(value)}>
            <span>{renderValue}{downscaled ? <span className="opacity-50">...</span> : null}</span>
        </Hint>
    )
}