export const isNegative = (num: string): boolean =>
    !isZero(num) && num.trim().startsWith('-')

export const isPositive = (num: string): boolean =>
    !isZero(num) && !isNegative(num)

export const scaleOf = (num: string): number => {
    const norm = normalize(num).replace('-', '')
    const dotAt = norm.indexOf('.')
    return dotAt >= 0 ? norm.length - dotAt - 1 : 1 - norm.trim().length
}

export const precisionOf = (num: string): number =>
    normalize(num).replace(/\D/g, '').length


export const parts = (num: string): [string, string] => {
    let offset = precisionOf(num) - Math.max(0, scaleOf(num))
    if (isNegative(num)) {
        offset += 1
    }
    return [num.slice(0, offset), num.slice(offset + 1)]
}

export const formatThousands = (num: string, delimiter: string): string => {
    if (num == null || delimiter.length === 0) {
        return num
    }

    const split = num.split('.')
    const before = split[0].replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${delimiter}`)
    const after = split[1] || ''

    return after.length > 0 ? `${before}.${after}` : before
}

const denormalizedScaleOf = (num: string): number => {
    const norm = num.trim().replace('-', '')
    const dotAt = norm.indexOf('.')
    return dotAt >= 0 ? norm.length - dotAt - 1 : 1 - norm.trim().length
}

export function rescale(num: string, scale: number): string {
    if (isZero(num)) return '0'

    const initialScale = denormalizedScaleOf(num)
    const rescale = scale - Math.max(initialScale, 0)

    if (rescale > 0) {
        // upscale
        if (initialScale <= 0) {
            return `${num}.0${"0".repeat(rescale - 1)}`
        }
        return `${num}${"0".repeat(rescale)}`
    }
    if (rescale < 0) {
        // downscale
        if (scale > 0) {
            const downScaled = num.slice(0, num.length + rescale)
            return !isZero(downScaled) ? downScaled : '0'
        }
        if (scale === 0) {
            const downScaled = num.slice(0, num.length - initialScale - 1)
            return !isZero(downScaled) ? downScaled : '0'
        }
        const units = num.slice(0, num.length - initialScale - 1)
        const zeroAt = units.indexOf('0')
        const unitScale = scaleOf(units)
        const unitFactor = zeroAt >= 0 ? units.length - zeroAt : 0
        if (isZero(units)) {
            return '0'
        }
        if (unitFactor === scale) { // Probably dead code
            return units
        }

        if (scale < unitScale) {
            return '0'
        }
        const rightPad = "0".repeat(unitFactor - scale)
        return units.slice(0, units.length - unitFactor + scale) + rightPad
    }

    return !isZero(num) ? num : '0'
}

export function truncate(units: string, decimals: number): string {
    let value = ''
    for (let i = 0; i <= decimals - units.length; i++) {
        value += "0"
    }
    value += units
    const insert = value.length - decimals
    return value.substring(0, insert) + '.' + value.substring(insert)
}

export const normalize = (num: string): string => {
    const normalized = num
        .trim()
        .replace(/(^(-?)0+(\d+))|((\.[0-9]*[^0]+)0+$)|\.0*$/g, '$2$3$5')

    return !isZero(normalized) ? normalized : '0'
}

export function isZero(num?: string | null): boolean | null {
    if (!num) {
        return null
    }

    return num.replace(/(\D|0)/g, '').length === 0
}