import cn from 'classnames'
import { Coin } from 'coin/types'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import CountDown from 'ui/CountDown'
import Num from 'ui/Num'

interface Props {
    productName: string
    productId: string
    storeDomain: string
    price: string
    coin: Coin
    storeName?: string | null
    expireIn?: string | null
}

export default function IntegrationInfo({
    storeDomain,
    storeName,
    productId,
    productName,
    price,
    coin,
    expireIn
}: Props) {
    const expireInDate = expireIn ? new Date(expireIn) : null
    const domain = useMemo(() => {
        try {
            const url: URL = new URL(storeDomain)
            return url.hostname
        } catch(error) {
            return null
        }
    }, [storeDomain])
    
    return (
        <>
            <div className="mt-4 px-4 flex flex-row items-center justify-between">
                {storeName && (
                    <div className={cn("mt-0 text-xl dark:text-neutral-500 text-neutral-400 font-extrabold font-title mb-0")}>{storeName}</div>
                )}
                {(expireInDate != null && storeName) && (
                    <>
                        <div className='grow' />
                        <div className='opacity-20 ml-4 mr-8'>
                            <CountDown till={expireInDate} />
                        </div>
                    </>
                )}
                <a
                    href={storeDomain}
                    target="_blank"
                    rel='noopener noreferrer'
                    className='no-underline transition-colors duration-200 dark:text-neutral-500 hover:dark:text-rose-200 text-neutral-400 hover:text-rose-300'
                >
                    {domain}
                </a>
                {(expireInDate != null && !storeName) && (
                    <>
                        <div className='grow' />
                        <div className='opacity-20 ml-8'>
                            <CountDown till={expireInDate} />
                        </div>
                    </>
                )}
            </div>
            <div className='mt-12 mb-16 px-4'>
                {/* <div className='text-sm mb-2 opacity-20'>Product</div> */}

                <div className='flex flex-row justify-between items-start' style={{ gap: '5%' }}>
                    <div>
                        <div className='text-2xl font-extrabold'>{productName}</div>
                        <div className='text-sm opacity-30'>
                            <FormattedMessage defaultMessage="ID:" id="tmcdrp" /> {productId}
                        </div>
                    </div>
                    <div className=''>
                        <div className='inline text-2xl font-bold font-mono'>
                            <Num value={price} />
                        </div>
                        <span className='text-2xl font-mono opacity-30 ml-2'>{coin.code}</span>
                    </div>
                </div>
            </div>
        </>
    )
}