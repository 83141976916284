import MultiCoinIcon from "coin/MultiCoinIcon"
import { Coin } from "coin/types"
import { PaymentForm, PaymentRequestForm } from "payment-form-setup/types"
import { Payment } from "payment/types"

export function CoinOption({ coin }: { coin: Coin }) {
    return (
        <div className="inline-block whitespace-nowrap text-left w-full">
            <MultiCoinIcon coin={coin} iconClassName="inline-block m-0" className='inline-block mr-1' />
            <span className='ml-2'>
                {coin.type === 'multi' ? coin.name : coin.code}
            </span>
        </div>
    )
}
