import cn from 'classnames'
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import CountDown from 'ui/CountDown'

interface Props {
    title: string
    description?: string | null
    expireIn?: string | null
}

export default function PaymentRequestInfo({
    title,
    description,
    expireIn
}: Props) {
    const expireInDate = expireIn ? new Date(expireIn) : null
    return (
        <>
            <div className={cn("text-left flex flex-row items-center justify-between mt-4 px-4", {
                'mb-8': !description,
                'mb-2': !!description,
            })}>
                <h1 className={cn("mt-0 mb-0 font-extrabold")}>{title} </h1>
                {(expireInDate != null) && (
                    <div className='opacity-20 ml-4'>
                        <CountDown till={expireInDate} />
                    </div>
                )}
            </div>
            {
                (description) && (
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        className="w-full px-4 mb-12"
                        components={{
                            a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />,
                            h1: ({ node, ...props }) => <h3 {...props} />,
                            h2: ({ node, ...props }) => <h4 {...props} />,
                            h3: ({ node, ...props }) => <h5 {...props} />,
                            h4: ({ node, ...props }) => <h5 {...props} />,
                            h5: ({ node, ...props }) => <h6 {...props} />,
                        }}
                        allowedElements={['a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'b', 'strong', 'em', 'i', 'blockquote', 'del', 'sup', 'li', 'ul', 'ol', 'table', 'thead', 'tbody', 'th', 'tr', 'td', 'code', 'pre', 'hr']}
                    >
                        {description}
                    </ReactMarkdown>
                )
            }
        </>
    )
}